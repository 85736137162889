













import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { WorkListMutations } from '@store/modules/work-list/Types';
import { IWork, IWorkListState } from "@store/modules/work-list/interfaces/Interfaces";

const NSWorkList = namespace('storeWorkList');

@Component({
    name: 'PopupFiltersWorkList',
    components: {
        PopupFilters: () => import('@components/popups/PopupFilters.vue'),
    }
})

export default class PopupFiltersWorkList extends Vue {
    $refs!: {
        popupFilter: HTMLFormElement,
    }

    @NSWorkList.State((state: IWorkListState) => state.viewingWork) viewingWork!: IWork;
    @NSWorkList.Getter('filters') filters!: any[];
    @NSWorkList.Getter('filtersApply') filtersApply!: boolean;
    @NSWorkList.Mutation(WorkListMutations.M_SET_FILTERS) setFilters!: (payload: any[]) => void;
    @NSWorkList.Mutation(WorkListMutations.M_SET_FILTERS_APPLY) setFiltersApply!: (payload: boolean) => void;

    @Prop({}) constructsToDisplay!: any[];

    @Watch('viewingWork')
    watchViewingWork(): void {
        this.closePopupForEmptyConstruct();
    }

    @Watch('constructsToDisplay')
    watchProjectId() {
        this.constructs = this.constructsToDisplay;
        this.setOptions();
    }

    @Watch('filtersCount')
    watchCount() {
        if (this.filtersApply) {
            this.$emit('set-filters-preview', this.popupData);
        }
    }

    mounted() {
        if (this.constructsToDisplay) {
            this.constructs = this.constructsToDisplay;
        }
    }

    popupData: any[] = [
        {
            title: 'Начало работ',
            titles: ['Начало работ от', 'Начало работ до'],
            block: 'startDate',
            type: 'dateRange',
            key: 'startDate',
            datesRange: {},
        },{
            title: 'Завершение работ',
            titles: ['Завершение работ от', 'Завершение работ до'],
            block: 'endDate',
            type: 'dateRange',
            key: 'endDate',
            datesRange: {},
        },{
            title: 'Статус',
            block: 'status',
            type: 'select',
            key: 'status.title',
            options: this.allStatuses(),
            selected: [],
        },{
            title: 'Отклонения от плана',
            block: 'differenceFromPlan',
            type: 'checkboxes',
            key: 'construct.plan',
            options: [{label: 'Показывать отклонения от плана'}],
            selected: [],
        },
    ];
    constructs: any[] = [];

    closePopupForEmptyConstruct(): void {
        if (!this.viewingWork.constructs.length) {
            this.closePopup();
        }
    }

    applyFilters() {
        this.setFiltersApply(true);
        this.updateStoreFilters();
        this.setOptions();
        this.$emit('set-filters-preview', this.popupData);
        this.closePopup();
    }

    setOptions() {
        this.popupData.forEach((item) => {
            if (item.block === 'status') {
                item.options = this.allStatuses();
            }
        })
    }

    cancelFilters() {
        this.setFiltersApply(false);

        this.popupData.forEach((item) => {
            if ((item.type === 'checkboxes' || item.type === 'select')) {
                item.selected = [];
            }
            if (item.type === 'dateRange') {
                item.datesRange = {};
            }
        });

        this.$refs.popupFilter.resetFilters();
        this.setFilters([]);
    }

    allStatuses(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.constructsToDisplay) {
            this.constructsToDisplay.forEach((item) => {
                if (item.status && item.status.title && !added.includes(item.status.title)) {
                    added.push(item.status.title);
                    output.push({label: item.status.title});
                }
            })
        }
        return output;
    }

    changeSelected(e, byPreview = false) {
        for (const item of this.popupData) {
            if ((item.type === 'checkboxes' || item.type === 'select') && item.title === e.section) {
                item.selected = e.selected;
            }
            if (item.type === 'dateRange' && item.title === e.section) {
                item.datesRange = e.datesRange;
            }
        }
        this.updateStoreFilters();
        if (byPreview && !e.datesRange) {
            this.$refs.popupFilter.changeSelectedData(e);
        }
        if (byPreview && e.datesRange) {
            this.$refs.popupFilter.changeSelectedDataDate(e);
        }
    }

    updateStoreFilters() {
        const filters: any[] = [];
        this.popupData.forEach((item) => {
            if ((item.type === 'checkboxes' || item.type === 'select') && item.selected.length) {
                filters.push(item);
            }
            if (item.type === 'dateRange') {
                filters.push(item);
            }
        });
        this.setFilters(filters);
    }

    get filtersCount(): number {
        let output: any[] = this.constructs;
        if (this.filters.length) {
            this.filters.forEach((filterObject) => {
                if (filterObject.type === 'checkboxes') {
                    if (filterObject.key === 'construct.plan') {
                        output = output.filter(work => work.plan !== work.fact);
                    }
                }
                if (filterObject.type === 'select') {
                    if (filterObject.key === 'status.title') {
                        output = output.filter(work => work.status ? filterObject.selected.includes(work.status.title) : false);
                    }
                }
                if (filterObject.type === 'dateRange' && (filterObject.datesRange.dateFrom || filterObject.datesRange.dateTo)) {
                    output = output.filter(
                        work => filterObject.datesRange.dateFrom ? Date.parse(work[filterObject.key]) >= Date.parse(filterObject.datesRange.dateFrom) : true,
                    );
                    output = output.filter(
                        work => filterObject.datesRange.dateTo ? Date.parse(work[filterObject.key]) <= Date.parse(filterObject.datesRange.dateTo) : true,
                    );
                }
            });
        }
        return output?.length || 0;
    }

    openPopup() {
        this.$refs.popupFilter.openPopup();
    }

    closePopup() {
        if (this.$refs.popupFilter) {
            this.$refs.popupFilter.closePopup();
        }
    }
}
